import { Box, Typography } from "@mui/material";
import React from "react";
import { Image } from "../BonusImage/bonus.image";
type IBonusItemProps = {
  active_bonus: boolean;
  bg_img: {
    name: string;
    src: string;
  };
  sub_img: {
    name: string;
    src: string;
  };
  sub_type: string;
  promo_code: string | undefined;
  fs?: {
    count: number;
    game: string;
  };
  pers?: {
    count: number;
    max: number;
    min: number;
  };
  title: string;
  sub_title: string;
  openInfo: () => void;
  promocodeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getBonus: () => void;
  playBonus: () => void;
};

export const BonusItem = ({
  bg_img,
  sub_img,
  sub_type,
  promo_code,
  fs,
  pers,
  title,
  active_bonus,
  sub_title,
  openInfo,
  promocodeHandler,
  getBonus,
  playBonus,
}: IBonusItemProps) => {
  return (
    <>
      {bg_img.name ? (
        <Image
          alt={bg_img.name}
          src={`${process.env.REACT_APP_SERVER_URL}/api/bonus/image?image_name=${bg_img.name}`}
          classname="bonus-card__bg-image"
        />
      ) : (
        <Box
          className="bonus-card__bg-image"
          sx={{ backgroundColor: "var(--primary-background-color)" }}
        />
      )}
      <Image
        alt={sub_img.name}
        src={`${process.env.REACT_APP_SERVER_URL}/api/bonus/image?image_name=${sub_img.name}`}
        classname="bonus-card__sub-image"
      />
      <Box className="bonus-card__bonus-type">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
        >
          <circle cx="7" cy="7" r="7" fill="white" fillOpacity="0.7" />
          <rect
            x="2.09961"
            y="2.09998"
            width="9.8"
            height="9.8"
            fill="url(#pattern0)"
          />
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use xlinkHref="#image0_1157_4905" transform="scale(0.015625)" />
            </pattern>
            <image
              id="image0_1157_4905"
              width="64"
              height="64"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADQUlEQVR4nO2bO2gVQRSG10RNxAd2aewUNVgo6SOioHYKgRTGgII2IoRUimLpqxBFI4qKnUVsFEUIaCn4CIKPRrTQIqKVFsud/x98HDlkA8slyZ3Nvm/mhynuvXNmzvnu7JnZmd0gyEki0m2M2W6MGQJw3BgzrJ9FpGsem67IZjiyGTLG9GtbQV1krd0GYBxASFKaCwAD4J61ti9m06ff6W9z2ITaprV2a1BVicgKkncA/J0tiFmC0nrXSI4ltLmtfQVVUhiGPQAmXYLIogB4rX0GVZCIdJN8UVTwMQiTlRgJJMeKDj5WrpYd/CYAv8sCEPXdWyaAWyX++zPlZinBi0gHye8VAPBDfSkcAMlehyH6B8BZAHsAXNDPjjbnI5tzLjZ6KRYOAMCupEmK5A0Hm+tJkyyAnYUDIDng4NihuI219kArG60TtwFw2AHaQOEAjDH9DgAeNAEYdAAw2ATgYSsb9SX3gAFsiAIYIXkCwCXHJPWU5Cm1IXkmygVzFq0T1VWbZy59RL6ozYj6CGB9loHvBfAmRZaOOzru0N94Rn1NAti94MBFZAmAi1k4UwaAqL9/OpNoLIkBkDydZfBlAIiVk5VY4qIkAImXzMxpiVviCEi2ZGZOS9ySAXxzCl5EVuXkQNkARERWtgTQaDTWtSsAjc0DaKXGYh8BYRj2tCsAp41UEel0vAevFQBdC2hsgYuY0y5vySPguVPwKj2WajcAAI4FrhKRLgCf2gUAgI8isjxIImvtFgC/6g4AwM8Fb52T3AzgQ10BAHhHcmOQRiLSaYw5COCxrqfTzBB5A4h8Ux8f6dG6c9b38vLymkt6ugPgfhaF5KhDf6MZ9pf++Jzk57pOg+q7B5BW9COA/hKgzwH0SZB+FqCfBrkY1wGYvqeu5SUA4G1qACQnagzgSWoAAK7UGMDloh6Hk4oC2JEagIgsI/m1hgC+iMjSIAsZY4brBqD5ecNUEpEOTSh1AaAbuJk/Pywia9JukRcBAMB79TXT4GckIqt1y7nCACZEZG2Qp2T69Pio7r9XCICeBRwp9BxApt8S20/yLoBXJKf0GGq+onVbtRu116qdKZIvtS7JfZV4d8jLy8vLyyuonf4DlsW+DqK8zPEAAAAASUVORK5CYII="
            />
          </defs>
        </svg>
        <Typography variant="body2" className="bonus-card__bonus-type-title">
          {sub_type === "chain"
            ? "Приветственный пакет бонусов"
            : !promo_code
            ? "Промокод"
            : fs && pers
            ? "Эксклюзивный бонус"
            : fs
            ? "Фриспины"
            : "Бонус к депозиту"}
        </Typography>
      </Box>
      <Typography variant="h1" className="bonus-card__bonus-title">
        {title}
      </Typography>
      <Typography
        variant="h2"
        className={`bonus-card__bonus-subtitle ${!promo_code && "promo_code"}`}
      >
        {sub_title}
      </Typography>
      {promo_code ? (
        <button className="bonus-card__bonus-rules-button" onClick={openInfo}>
          Условия бонуса
        </button>
      ) : (
        <input
          className="bonus-card__bonus-promo-input"
          onChange={promocodeHandler}
          placeholder="Промо-код"
        />
      )}
      {active_bonus ? (
        <button
          className="bonus-card__bonus-button primary-hover"
          onClick={playBonus}
        >
          Играть
        </button>
      ) : (
        <button
          className="bonus-card__bonus-button primary-hover"
          onClick={getBonus}
        >
          Получить бонус
        </button>
      )}
    </>
  );
};
