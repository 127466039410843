import { PayloadAction } from "@reduxjs/toolkit";
import { ProfileAction } from "./typing/profile.enum";
import {
  IPartner,
  IPartnerBonus,
  IPartnerGeneralData,
  IPartnerLink,
  IProfile,
} from "./typing/profile.interface";

const reducers = {
  [ProfileAction.SET_PARTNER_LINK]: (
    state: IProfile,
    action: PayloadAction<IPartnerLink[]>
  ): void => {
    state.partner.links = action.payload;
  },
  [ProfileAction.REMOVE_LOCAL_PARTNER_LINK]: (
    state: IProfile,
    action: PayloadAction<{
      active: boolean;
      id: string;
      link_name: string;
      registration_link: string | null;
      registration_promocode: string | null;
    }>
  ): void => {
    const updatedLinks = state.partner.links.filter(
      (link) => link.id !== action.payload.id
    );
    state.partner.links = updatedLinks;
  },
  [ProfileAction.CREATE_LOCAL_PARTNER_LINK]: (
    state: IProfile,
    action: PayloadAction<{
      active: boolean;
      id: string;
      link_name: string;
      registration_link: string | null;
      registration_promocode: string | null;
    }>
  ): void => {
    const { id, link_name, registration_link, registration_promocode } =
      action.payload;

    const newLink: IPartnerLink = {
      id,
      link_name,
      registration_link: registration_link ? registration_link : null,
      registration_promocode,
      data: [
        [
          "Регистрация",
          "Депозити",
          "Cумма депозитов",
          "Cумма вывода",
          "Комиссия за депозит",
          "Комиссия за снятие средств",
          "Комиссия за игри",
          "Награда",
        ],
        [0, 0, 0, 0, 0, 0, 0, 0],
      ],
    };
    //@ts-ignore
    state.partner.links.push(newLink);
    // state.partner.links = updatedLinks;
  },
  [ProfileAction.SET_PARTNER_BONUSES]: (
    state: IProfile,
    action: PayloadAction<IPartnerBonus[]>
  ): void => {
    state.partner.bonus = action.payload;
  },
  [ProfileAction.SET_PARTNER_GENERAL]: (
    state: IProfile,
    action: PayloadAction<IPartnerGeneralData>
  ): void => {
    state.partner.general = action.payload;
  },
  [ProfileAction.SET_PARTNER_LINK_LIMIT]: (
    state: IProfile,
    action: PayloadAction<number>
  ): void => {
    state.partner.link_limit = action.payload;
  },
  [ProfileAction.SET_PARTNER_INFORMATION]: (
    state: IProfile,
    action: PayloadAction<{
      partner_statistics: IPartner["partner_statistics"];
    }>
  ): void => {
    state.partner.partner_statistics = action.payload.partner_statistics;
  },
};

export default reducers;
