import { PayloadAction } from "@reduxjs/toolkit";
import { BonusesAction } from "./typing/bonuses.enum";
import { IActiveBonus, IBonus, IBonuses } from "./typing/bonuses.interface";

const reducers = {
  [BonusesAction.ADD_BONUSES]: (
    state: IBonuses,
    action: PayloadAction<IBonus[]>
  ) => {
    const newArray: IBonus[] = [];
    action.payload.forEach((bonus: IBonus) => {
      const elem = state.bonuses.find((stateBonus: IBonus) => {
        return stateBonus.id === bonus.id;
      });
      if (elem) {
        return;
      }
      newArray.push(bonus);
    });
    state.bonuses = [...state.bonuses, ...newArray];
    return state;
  },
  [BonusesAction.ADD_MY_ACTIVATED_BONUSES]: (
    state: IBonuses,
    action: PayloadAction<IActiveBonus[]>
  ) => {
    const newArray: IActiveBonus[] = [];
    action.payload.forEach((bonus: IActiveBonus) => {
      const elem = state.activatedBonuses.find((stateBonus: IActiveBonus) => {
        return stateBonus.id === bonus.id;
      });
      if (elem) {
        return;
      }
      newArray.push(bonus);
    });
    state.activatedBonuses = [...state.activatedBonuses, ...newArray];
    return state;
  },
  [BonusesAction.ADD_AVAILABLE_BONUSES]: (
    state: IBonuses,
    action: PayloadAction<IBonus[]>
  ) => {
    const newArray: IBonus[] = [];
    action.payload.forEach((bonus: IBonus) => {
      const elem = state.available.find((stateBonus: IBonus) => {
        return stateBonus.id === bonus.id;
      });
      if (elem) {
        return;
      }
      newArray.push(bonus);
    });
    state.available = [...state.available, ...newArray];
    return state;
  },
};

export default reducers;
