import { createAsyncThunk } from "@reduxjs/toolkit";
import { PaymentService } from "../../../services/payments/payment.service";
// import { RootState } from "../../rootStore";
import { IStore } from "../../typing/interface";
import { setAuthorizationModal } from "../auth/auth.slice";
import { logout, verifyAuthUser } from "../auth/auth.thunk";
import {
  setDepositHistory,
  setPaymentMethods,
  setWithdrawalHistory,
} from "./payment.slice";

export const createDepositPayment = createAsyncThunk<
  void,
  {
    amount: number;
    // payment_system: string;
    method_id: string;
    promocode?: string;
  },
  { state: IStore }
>("payment/create", async (_, { getState, dispatch }) => {
  const { amount, method_id, promocode } = _;
  // debugger;
  try {
    const paymentService = new PaymentService();

    const { result, error } = await paymentService.createPayment({
      amount,
      type: "refill",
      method_id,
      promocode,
    });

    if (error && error.response.status === 401) {
      dispatch(logout());
      dispatch(setAuthorizationModal(true));
      // return error.response.data;
    }

    if (result) {
      // debugger;
      // if (payment_system === "freekassa") {
      window.location.href = result.paymentLink;
      // }

      // if (payment_system === "betatransfer") {
      //   window.location.href = result.paymentLink.urlPayment;
      // }

      sessionStorage.removeItem("enprsl");
      sessionStorage.removeItem("enamsl");
    }
  } catch (error) {
    // debugger;
  }
});

export const successfulFreekassa = createAsyncThunk<
  void,
  { order_id: any; payment_system: any },
  { state: IStore }
>("payment/successfulFreekassa", async (_, { getState, dispatch }) => {
  const { order_id, payment_system } = _;
  // debugger;

  const paymentService = new PaymentService();

  const { result, error } = await paymentService.successfulFreekassa(
    order_id,
    payment_system
  );
  // debugger;
  if (error && error.response.status === 401) {
    dispatch(logout());
    dispatch(setAuthorizationModal(true));
    return error.response.data;
  }

  if (result) {
    dispatch(verifyAuthUser());
  }

  return { result, error: error.response.data };
  // window.location.href = "https://enomos.store/";
});

export const failureRepay = createAsyncThunk<
  void,
  { order_id: any; payment_system: any },
  { state: IStore }
>("payment/failureRepay", async (_, { getState, dispatch }) => {
  const { order_id, payment_system } = _;

  try {
    const paymentService = new PaymentService();

    const { result, error } = await paymentService.failureRepay(
      order_id,
      payment_system
    );

    if (error && error.response.status === 401) {
      dispatch(logout());
      dispatch(setAuthorizationModal(true));
      // return error.response.data;
    }

    if (result) {
      // debugger;
      // window.location.href = result.paymentLink;
    }
  } catch (error) {
    // debugger;
  }
});

export const getDepositHistory = createAsyncThunk<
  void,
  { page: number; filter?: {} },
  { state: IStore }
>("payment/getDepositHistory", async (_, { getState, dispatch }) => {
  let { page } = _;

  page++;
  try {
    const paymentService = new PaymentService();

    const { result, error } = await paymentService.getPaymentHistory(page, {
      type: "refill",
    });

    if (error && error.response.status === 401) {
      dispatch(logout());
      dispatch(setAuthorizationModal(true));
    }
    if (
      error &&
      error.response.status === 400 &&
      error.response.data.message == "History not found"
    ) {
      return;
    }

    if (result) {
      dispatch(
        setDepositHistory({
          payments: result.paymentHistory,
          count: result.count,
          page: result.page,
        })
      );
    }
  } catch (error) {
    // debugger;
  }
});

export const createWithdrawalRequest = createAsyncThunk<
  void,
  { id: string },
  { state: IStore }
>("payment/createWithdrawalRequest", async (_, { getState, dispatch }) => {
  let { id } = _;

  try {
    const paymentService = new PaymentService();

    const { result, error } = await paymentService.cancelWithdrawal(id);

    if (error && error.response.status === 401) {
      dispatch(logout());
      dispatch(setAuthorizationModal(true));
    }
    if (
      error &&
      error.response.status === 400 &&
      error.response.data.message == "Error"
    ) {
      return;
    }

    if (result) {
      dispatch(verifyAuthUser());
      return result;
    }
  } catch (error) {
    // debugger;
  }
});

export const getWithdrawalHistory = createAsyncThunk<
  void,
  { page: number; filter?: {} },
  { state: IStore }
>("payment/getWithdrawalHistory", async (_, { getState, dispatch }) => {
  let { page } = _;

  page++;
  try {
    const paymentService = new PaymentService();

    const { result, error } = await paymentService.getPaymentHistory(page, {
      type: "withdrawals",
    });

    if (error && error.response.status === 401) {
      dispatch(logout());
      dispatch(setAuthorizationModal(true));
    }
    if (
      error &&
      error.response.status === 400 &&
      error.response.data.message == "History not found"
    ) {
      return;
    }

    if (result) {
      dispatch(
        setWithdrawalHistory({
          payments: result.paymentHistory,
          count: result.count,
          page: result.page,
        })
      );
    }
  } catch (error) {
    // debugger;
  }
});

export const validatePromocode = createAsyncThunk<any, { promocode: string }>(
  "payment/validatePromocode",
  async (_, { getState, dispatch }) => {
    const { promocode } = _;

    const paymentService = new PaymentService();
    const promocodeValide = await paymentService.validatePromocode(promocode);

    return promocodeValide;
  }
);

export const createWithdrawalPayment = createAsyncThunk<
  { message: string },
  {
    amount: number;
    credentials: string;
    method_id: string;
    bank_id?: string;
  }
>("payment/createWD", async (_, { getState, dispatch }) => {
  const { amount, method_id, credentials, bank_id } = _;
  const paymentService = new PaymentService();
  const response = await paymentService.createPayment({
    amount,
    type: "withdrawals",
    method_id,
    credentials,
    bank_id,
  });
  //@ts-ignore
  if (response) await dispatch(verifyAuthUser());
  // console.log(response.result);
  return response.result;
});

export const verifyDeposits = createAsyncThunk("verifyDeposits", async () => {
  const paymentService = new PaymentService();
  const { result, error } = await paymentService.verifyDeposits();
  if (error && error.response.status === 400) {
    return error.response.data;
  }
  if (error) {
    return { error: error.message };
  }
  if (result) {
    return result.deposits;
  }
});

export const verifyWithrawals = createAsyncThunk(
  "verifyWithdrawals",
  async () => {
    const paymentService = new PaymentService();
    const { result, error } = await paymentService.verifyWithrawals();
    if (error && error.response.status === 400) {
      return error.response.data;
    }
    if (error) {
      return { error: error.message };
    }
    if (result) {
      return result.withdrawals;
    }
  }
);

export const getPaymentMethods = createAsyncThunk(
  "getPaymentMethods",
  async (_, { getState, dispatch }) => {
    const state = getState() as IStore;

    if (state.payments.paymentMethods.methods.length) {
      return;
    }
    const paymentService = new PaymentService();
    const { result, error } = await paymentService.getPaymentMethods();
    if (error && error.response.status === 400) {
      // return error.response.data;
    }
    if (error) {
      // return { error: error.message };
    }
    if (result) {
      // debugger;
      dispatch(setPaymentMethods(result));
    }
  }
);
