import { createSlice } from "@reduxjs/toolkit";
import reducers from "./profile.reducers";
import { IProfile } from "./typing/profile.interface";
// import reducers from "./user.reducers";
// import { IUser } from "./typing/user.interface";

export const initialStateProfile: IProfile = {
  partner: {
    link_limit: 0,
    partner_statistics: "standard",
    general: [
      [
        "Регистрация",
        "Депозити",
        "Cумма депозитов",
        "Cумма вывода",
        "Комиссия за депозит",
        "Комиссия за снятие средств",
        "Комиссия за игри",
        "Награда",
      ],
      [0, 0, 0, 0, 0, 0, 0, 0],
    ],
    links: [],
    bonus: [],
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialStateProfile,
  reducers,
});

// Action creators are generated for each case reducer function
export const {
  setPartnerLink,
  setPartnerBonuses,
  setPartnerLinkLimit,
  setPartnerInformation,
  setPartnerGeneral,
  removeLocalPartnerLink,
  createLocalPartnerLink,
} = profileSlice.actions;

export default profileSlice.reducer;
