import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import "./active.bonus.item.css";
import { Image } from "../BonusesItem/BonusImage/bonus.image";
import { BonusRules } from "../BonusesItem/BonusRules/bonus.rules";
import { IActiveBonus } from "../../../store/models/bonuses/typing/bonuses.interface";
import { useNavigate } from "react-router-dom";

interface IActiveBonusItemProps extends IActiveBonus {
  sub_img: {
    name: string;
    src: string;
  };
  customClassName: string;
  type: IActiveBonus["bonus_type"];
}
export const ActiveBonusItem = ({
  id,
  title,
  sub_img,
  status,
  wagered,
  wager,
  start_date,
  end_date,
  type,
  customClassName,
  bonus,
}: IActiveBonusItemProps) => {
  const [openedRules, setOpenedRules] = useState(false);
  const [loading, setLoading] = useState(false);
  const navagate = useNavigate();
  const openRulesHandler = () => {
    setOpenedRules(true);
  };
  const closeRulesHandler = () => {
    setOpenedRules(false);
  };
  const playHandler = () => {
    console.log(type);
    if (type === "FS") {
      if (bonus.fs) {
        navagate(`/slots/${bonus.fs.game}.game`);
      }
    } else {
      navagate(`/lobby`);
    }
  };
  const depositHandler = () => {
    navagate("/profile/payments");
  };

  // const getBonus = async () => {

  //   if (promocode) {
  //     setLoading(true);

  //     const message: any = await dispatch(activateGlobalBonus({ promocode }));
  //     if (message.payload) {
  //       if (
  //         message.payload ===
  //         "Для получения бонуса, аккаунт необходимо верифицировать любым удобным для вас способом"
  //       ) {
  //         setInfoMessageButton(t("buttons.getverification"));
  //       } else if (message.payload === "Этот бонус был использован ранее") {
  //         setInfoMessageButton(t("buttons.close"));
  //       } else if (
  //         message.payload ===
  //         "Бонус был успешно активирован. Пополните игровой счет, чтобы получить его"
  //       ) {
  //         setInfoMessageButton(t("buttons.shorttopup"));
  //       } else if (message.payload === "Бонус был успешно активирован") {
  //         setInfoMessageButton(t("buttons.play"));
  //       } else if (
  //         message.payload ===
  //         "У вас есть другой активный бонус. Завершите его и после сможете принять участие в этом"
  //       ) {
  //         setInfoMessageButton(t("buttons.play"));
  //       } else if (message.payload === "Bonus not found") {
  //         setInfoMessageButton(t("buttons.close"));
  //       }
  //     } else {
  //       setInfoMessageButton(t("buttons.play"));
  //     }
  //     setLoading(false);
  //     setInfoMessage({
  //       show: true,
  //       message: message.payload,
  //     });
  //   } else {
  //     setInfoMessage({ show: true, message: "Бонус не найден" });
  //   }
  // };

  return (
    <Box className={`${customClassName && ""} my-bonus-card__container`}>
      <Box
        className="my-bonus-card__bg-image"
        sx={{ backgroundColor: "var(--primary-background-color)" }}
      />
      <Box className="my-bonus-card__bonus-type">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
        >
          <circle cx="7" cy="7" r="7" fill="white" fillOpacity="0.7" />
          <rect
            x="2.09961"
            y="2.09998"
            width="9.8"
            height="9.8"
            fill="url(#pattern0)"
          />
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use xlinkHref="#image0_1157_4905" transform="scale(0.015625)" />
            </pattern>
            <image
              id="image0_1157_4905"
              width="64"
              height="64"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADQUlEQVR4nO2bO2gVQRSG10RNxAd2aewUNVgo6SOioHYKgRTGgII2IoRUimLpqxBFI4qKnUVsFEUIaCn4CIKPRrTQIqKVFsud/x98HDlkA8slyZ3Nvm/mhynuvXNmzvnu7JnZmd0gyEki0m2M2W6MGQJw3BgzrJ9FpGsem67IZjiyGTLG9GtbQV1krd0GYBxASFKaCwAD4J61ti9m06ff6W9z2ITaprV2a1BVicgKkncA/J0tiFmC0nrXSI4ltLmtfQVVUhiGPQAmXYLIogB4rX0GVZCIdJN8UVTwMQiTlRgJJMeKDj5WrpYd/CYAv8sCEPXdWyaAWyX++zPlZinBi0gHye8VAPBDfSkcAMlehyH6B8BZAHsAXNDPjjbnI5tzLjZ6KRYOAMCupEmK5A0Hm+tJkyyAnYUDIDng4NihuI219kArG60TtwFw2AHaQOEAjDH9DgAeNAEYdAAw2ATgYSsb9SX3gAFsiAIYIXkCwCXHJPWU5Cm1IXkmygVzFq0T1VWbZy59RL6ozYj6CGB9loHvBfAmRZaOOzru0N94Rn1NAti94MBFZAmAi1k4UwaAqL9/OpNoLIkBkDydZfBlAIiVk5VY4qIkAImXzMxpiVviCEi2ZGZOS9ySAXxzCl5EVuXkQNkARERWtgTQaDTWtSsAjc0DaKXGYh8BYRj2tCsAp41UEel0vAevFQBdC2hsgYuY0y5vySPguVPwKj2WajcAAI4FrhKRLgCf2gUAgI8isjxIImvtFgC/6g4AwM8Fb52T3AzgQ10BAHhHcmOQRiLSaYw5COCxrqfTzBB5A4h8Ux8f6dG6c9b38vLymkt6ugPgfhaF5KhDf6MZ9pf++Jzk57pOg+q7B5BW9COA/hKgzwH0SZB+FqCfBrkY1wGYvqeu5SUA4G1qACQnagzgSWoAAK7UGMDloh6Hk4oC2JEagIgsI/m1hgC+iMjSIAsZY4brBqD5ecNUEpEOTSh1AaAbuJk/Pywia9JukRcBAMB79TXT4GckIqt1y7nCACZEZG2Qp2T69Pio7r9XCICeBRwp9BxApt8S20/yLoBXJKf0GGq+onVbtRu116qdKZIvtS7JfZV4d8jLy8vLyyuonf4DlsW+DqK8zPEAAAAASUVORK5CYII="
            />
          </defs>
        </svg>

        <Typography variant="body2" className="my-bonus-card__bonus-type-title">
          {type === "FS" && "Фриспины"}
          {type === "PR" && "Бонус к депозиту"}
        </Typography>
      </Box>
      <Box className="my-bonus-card__bonus-type-title">
        <Image
          alt={sub_img.name}
          src={`${process.env.REACT_APP_SERVER_URL}/api/bonus/image?image_name=${sub_img.name}`}
          classname="my-bonus-card__my-bonus-sub-image"
        />
      </Box>
      <Box className="my-bonus-card__my-bonus-container">
        {type === "PR" && status != "active" ? (
          <>
            <Box className="my-bonus-card__my-bonus-wager-container">
              <Box
                className="box"
                sx={{
                  "--wager": `${Math.floor((+wagered / +wager) * 100) || 0}%`,
                }}
              >
                <Box
                  className={`circle ${
                    (status === "expired" ||
                      status === "fail" ||
                      status === "canceled" ||
                      status === "wagered") &&
                    "history"
                  }`}
                >
                  <h2>
                    {Math.floor((+wagered / +wager) * 100) || 0}
                    <small>%</small>
                  </h2>
                </Box>
              </Box>
              <Box className="my-bonus-card__my-bonus-wager">
                <Box className="my-bonus-card__my-bonus-wager-title">
                  Отыграно:
                </Box>
                {`${+wagered} / ${+wager}`}
              </Box>
            </Box>
          </>
        ) : (
          <></>
        )}

        {type === "FS" &&
          status != "active" &&
          status != "deposit" &&
          status != "use" && (
            <>
              <Box className="my-bonus-card__my-bonus-wager-container">
                <Box
                  className="box"
                  sx={{
                    "--wager": `${Math.floor((+wagered / +wager) * 100) || 0}%`,
                  }}
                >
                  <Box
                    className={`circle ${
                      (status === "expired" ||
                        status === "fail" ||
                        status === "canceled" ||
                        status === "wagered") &&
                      "history"
                    }`}
                  >
                    <h2>
                      {Math.floor((+wagered / +wager) * 100) || 0}
                      <small>%</small>
                    </h2>
                  </Box>
                </Box>
                <Box className="my-bonus-card__my-bonus-wager">
                  <Box className="my-bonus-card__my-bonus-wager-title">
                    Отыграно:
                  </Box>
                  {`${+wagered} / ${+wager}`}
                </Box>
              </Box>
            </>
          )}

        <Box className="my-bonus-card__description-container">
          <Typography variant="h1" className="my-bonus-card__bonus-title">
            {title}
          </Typography>
          <Typography variant="h2" className="my-bonus-card__bonus-subtitle">
            {bonus.sub_title}
          </Typography>
          <button
            className="my-bonus-card__bonus-rules-button"
            onClick={openRulesHandler}
          >
            Условия бонуса
          </button>
          {status === "active" ? (
            <button
              className="my-bonus-card__bonus-button"
              onClick={depositHandler}
            >
              Пополнить
            </button>
          ) : status === "deposit" ||
            status === "use" ||
            status === "wagering" ? (
            <button
              className="my-bonus-card__bonus-button"
              onClick={playHandler}
            >
              Играть
            </button>
          ) : (
            ""
          )}
        </Box>
      </Box>
      <BonusRules
        {...bonus}
        sub_img={sub_img}
        open={openedRules}
        close={closeRulesHandler}
        activate={() => {}}
        active_bonus={true}
        playBonus={playHandler}
      />
    </Box>
  );
};
