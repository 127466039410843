import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "./ProfileNoActiveBonuses.css";
import { useFormik } from "formik";
import bg from "../../../../assets/slider/desc_bd_2.jpg";
import { setRegistrationModal } from "../../../../store/models/auth/auth.slice";
import { activateGlobalBonus } from "../../../../store/models/bonuses/bonuses.thunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { bonusesListItemSelector } from "../../../Bonuses/Bonuses.selector";
import { BonusDialog } from "../../../Bonuses/BonusesItem/BonusDialog/bonus.dialog";

export const ProfileNoActiveBonuses = () => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      promocode: "",
    },
    onSubmit: () => {
      getBonus();
    },
  });
  const { auth } = useAppSelector(bonusesListItemSelector);
  const [infoMessage, setInfoMessage] = useState({ show: false, message: "" });
  const [infoMessageButton, setInfoMessageButton] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getBonus = async () => {
    if (!auth) {
      dispatch(setRegistrationModal(true));
      return;
    }
    if (formik.values.promocode) {
      setLoading(true);

      const message: any = await dispatch(
        activateGlobalBonus({ promocode: formik.values.promocode })
      );
      if (message.payload) {
        if (
          message.payload ===
          "Для получения бонуса, аккаунт необходимо верифицировать любым удобным для вас способом"
        ) {
          setInfoMessageButton(t("buttons.getverification"));
        } else if (message.payload === "Этот бонус был использован ранее") {
          setInfoMessageButton(t("buttons.close"));
        } else if (
          message.payload ===
          "Бонус был успешно активирован. Пополните игровой счет, чтобы получить его"
        ) {
          setInfoMessageButton(t("buttons.shorttopup"));
        } else if (message.payload === "Бонус был успешно активирован") {
          setInfoMessageButton(t("buttons.play"));
        } else if (
          message.payload ===
          "У вас есть другой активный бонус. Завершите его и после сможете принять участие в этом"
        ) {
          setInfoMessageButton(t("buttons.play"));
        } else if (message.payload === "Этот бонус не активен") {
          setInfoMessageButton(t("buttons.close"));
        } else if (message.payload === "Бонус не найден") {
          setInfoMessageButton(t("buttons.close"));
        }
      } else {
        setInfoMessageButton(t("buttons.play"));
      }
      setLoading(false);
      setInfoMessage({
        show: true,
        message: message.payload,
      });
    } else {
      setInfoMessage({ show: true, message: "Бонус не найден" });
    }
  };

  const dialogHandler = () => {
    if (
      infoMessage.message ===
      "Для получения бонуса, аккаунт необходимо верифицировать любым удобным для вас способом"
    ) {
      navigate("/profile/personal-info/verification");
    } else if (
      infoMessage.message ===
      "Бонус был успешно активирован. Пополните игровой счет, чтобы получить его"
    ) {
      navigate("/profile/payments");
    } else if (infoMessage.message === "Бонус был успешно активирован") {
      navigate("/lobby");
    } else if (infoMessage.message === "Этот бонус не активен") {
      navigate("/");
    }
    setInfoMessage({ show: false, message: "" });
  };
  const closeDialog = () => setInfoMessage({ show: false, message: "" });
  return (
    <>
      <Box className="no-active-bonuses__container">
        <Box className="no-active-bonuses__promocode">
          <img
            src={bg}
            className="no-active-bonuses__promocode-image"
            alt="background"
          />
          <Typography
            variant="body2"
            className="no-active-bonuses__promocode-title"
          >
            {t("bonuses.title")}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <input
              className="no-active-bonuses__promocode-input"
              id="promocode"
              placeholder={t("bonuses.promocode")}
              value={formik.values.promocode}
              onChange={formik.handleChange}
            />
          </form>
        </Box>
      </Box>
      <Box
        className="no-bonuses"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" color="textPrimary">
          {t("main.profile.bonuses.no_bonuses_text")}
        </Typography>
        <Link to="/bonuses" className="no-bonuses__link">
          {t("main.profile.bonuses.no_bonuses_button")}
        </Link>
      </Box>
      <BonusDialog
        infoMessage={infoMessage}
        loading={loading}
        infoMessageButton={infoMessageButton}
        closeDialog={closeDialog}
        dialogHandler={dialogHandler}
      />
    </>
  );
};
