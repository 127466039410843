import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileAction } from "./typing/profile.enum";
import { IStore } from "../../typing/interface";
import { ProfileService } from "../../../services/profile/profile.service";
import { verifyAuthUser } from "../auth/auth.thunk";
import { changeActiveWallet, updateVerification } from "../user/user.slice";
import { UserService } from "../../../services/user/user.service";
import { PartnerService } from "../../../services/partner/partner.service";
import {
  createLocalPartnerLink,
  removeLocalPartnerLink,
  setPartnerBonuses,
  setPartnerGeneral,
  setPartnerInformation,
  setPartnerLink,
  setPartnerLinkLimit,
} from "./profile.slice";
import { ICreatePartnerLinkResponse } from "./typing/profile.interface";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { ICurrency } from "../user/typing/user.interface";
dayjs.extend(utc);
export const savePersonInfo = createAsyncThunk<
  { error: string },
  {
    name: string;
    surname: string;
    secondname: string;
    date: string;
    sex: string;
  },
  { state: IStore }
>(ProfileAction.SAVE_PERSONAL_INFO, async (data, { dispatch }) => {
  const profileService = new ProfileService();

  const { result, error } = await profileService.savePersonalIngo(
    data.name,
    data.surname,
    data.secondname,
    data.date,
    data.sex
  );
  if (error && error.response.status === 400) {
    return error.response.data;
  }
  if (result) {
    dispatch(verifyAuthUser());
    return;
  }
});

export const addPhone = createAsyncThunk<
  { error: string },
  {
    phone: string;
  },
  { state: IStore }
>(ProfileAction.ADD_PHONE, async (data, { dispatch }) => {
  const profileService = new ProfileService();

  const { result, error } = await profileService.addPhone(data.phone);
  if (error && error.response.status === 400) {
    return error.response.data;
  }
  if (result) {
    dispatch(verifyAuthUser());
    return;
  }
});

export const changeWallet = createAsyncThunk<
  { error: string },
  {
    currency: ICurrency;
  },
  { state: IStore }
>(ProfileAction.CHANGE_WALLET, async (data, { getState, dispatch }) => {
  const state = getState();
  const userService = new UserService(state.user);
  const wallet = userService.getUserActiveWallet();

  if (wallet.currency.id === data.currency.id) return;
  // dispatch(changeActiveWallet(data.wallet));
  const profileService = new ProfileService();

  const { result, error } = await profileService.changeWallet(data.currency);
  if (error && error.response.status === 400) {
    return error.response.data;
  }
  if (result) {
    // debugger;
    dispatch(changeActiveWallet(data.currency.id));
    return;
  }
});

export const verificationUser = createAsyncThunk<
  { error: string },
  {
    passport: File | null;
    selfie: File | null;
    additional: File | null;
  },
  { state: IStore }
>(ProfileAction.CHANGE_WALLET, async (data, { getState, dispatch }) => {
  const state = getState();

  const userId = state.user.id;
  // const userService = new UserService(state.user);
  // const file = userService.verification(files);

  // if (wallet.id == data.wallet) return;
  // dispatch(changeActiveWallet(data.wallet));
  //
  // debugger;
  const profileService = new ProfileService();
  const { result, error } = await profileService.verification(
    { ...data },
    userId
  );
  if (error && error.response.status === 400) {
    return error.response.data;
  }
  // debugger;
  if (result) {
    dispatch(
      updateVerification({
        state: result.person.verification,
        stage: result.person.verificationStage,
      })
    );
    return result;
  }
});

// --------------------------------------------------------
export const getPartnerLinks = createAsyncThunk<
  null,
  undefined,
  { state: IStore }
>(ProfileAction.GET_PARTNER_LINKS, async (_, { getState, dispatch }) => {
  // const state = getState();

  const partnerService = new PartnerService();

  const { result, error } = await partnerService.getPartnerLink();

  if (result) {
    dispatch(setPartnerLink(result.links));
    // dispatch(setPartnerLink(result.promocode));

    return null;
  }
  return null;
});

export const getGeneralInfo = createAsyncThunk<
  null,
  { startDate: Dayjs | null; endDate: Dayjs | null },
  { state: IStore }
>(
  ProfileAction.GET_GENERAL_INFO,
  async ({ startDate, endDate }, { dispatch }) => {
    const partnerService = new PartnerService();

    let specificStartDate = "";
    let specificEndDate = "";

    if (startDate) {
      const adjustedStartDate = startDate.utc().startOf("day");
      if (!isNaN(adjustedStartDate.unix())) {
        specificStartDate = `${adjustedStartDate.unix() * 1000}`;
      }
    }
    if (endDate) {
      const adjustedEndDate = endDate.utc().endOf("day");
      if (!isNaN(adjustedEndDate.unix())) {
        specificEndDate = `${adjustedEndDate.unix() * 1000}`; // Множимо на 1000
      }
    }

    const { result, error } = await partnerService.getGeneralInfo(
      specificStartDate,
      specificEndDate
    );

    if (result) {
      const {
        bonus,
        link_limit,
        partner_statistics,
        data: { general, links },
      } = result;

      dispatch(setPartnerBonuses(bonus));
      dispatch(setPartnerLinkLimit(link_limit));
      dispatch(setPartnerGeneral(general));
      dispatch(setPartnerInformation({ partner_statistics }));
    }

    return null;
  }
);

export const getLinkslInfo = createAsyncThunk(
  ProfileAction.GET_LINKS_INFO,
  async (_, { getState, dispatch }) => {
    const partnerService = new PartnerService();

    const { result, error } = await partnerService.getLinksInfo();

    if (result) {
      const {
        data: { general, links },
      } = result;

      dispatch(setPartnerLink(links));
      return null;
    }
    return null;
  }
);

export const createPartnerLink = createAsyncThunk<
  { data: ICreatePartnerLinkResponse },
  {
    name: string;
    bonus: number;
  },
  { state: IStore }
>(ProfileAction.CREATE_PARTNER_LINK, async (data, { getState, dispatch }) => {
  const partnerService = new PartnerService();

  const { result, error } = await partnerService.createPartnerLink(
    data.name,
    data.bonus
  );
  if (error && error.response.status === 400) {
    return error.response.data;
  }
  if (result) {
    dispatch(createLocalPartnerLink(result.link));
  }
});
export const createPartnerPromocode = createAsyncThunk<
  { data: ICreatePartnerLinkResponse },
  {
    name: string;
    promocode: string;
    bonus: number;
  },
  { state: IStore }
>(ProfileAction.CREATE_PARTNER_LINK, async (data, { getState, dispatch }) => {
  const { bonus, name, promocode } = data;

  const partnerService = new PartnerService();

  const { result, error } = await partnerService.createPartnerPromocode(
    name,
    promocode,
    bonus
  );

  if (error && error.response.status === 400) {
    return error.response.data;
  }
  if (result) {
    // debugger;
    // {
    //   active: boolean;
    //   id: string;
    //   link_name: string;
    //   registration_link: string | null;
    //   registration_promocode: string | null;
    // }
    dispatch(createLocalPartnerLink(result.promocode));
  }
});
export const removePartnerLink = createAsyncThunk<
  { data: ICreatePartnerLinkResponse },
  {
    link: string;
  },
  { state: IStore }
>(ProfileAction.REMOVE_PARTNER_LINK, async (data, { getState, dispatch }) => {
  const { link } = data;

  const partnerService = new PartnerService();

  const { result, error } = await partnerService.removePartnerLink(link);

  if (error && error.response.status === 400) {
    return error.response.data;
  }
  if (result) {
    dispatch(removeLocalPartnerLink(result.link));
  }
});
