import { BonusesAction } from "./typing/bonuses.enum";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IStore } from "../../typing/interface";
import { BonusesService } from "../../../services/bonuses/bonuses.service";
import {
  addMyActivatedBonuses,
  addAvailableBonuses,
  addBonuses,
  // addBonusBGImage,
  // addBonusSubImage,
  // addMyActivatedBonusSubImage,
  // addAvailableBonusBGImage,
  // addAvailableBonusSubImage,
} from "./bonuses.slice";

export const fetchBonuses = createAsyncThunk<null, {}, { state: IStore }>(
  BonusesAction.FETCH_BONUSES,
  async (_, { getState, dispatch }) => {
    const bonusesService = new BonusesService();

    const { result, error } = await bonusesService.fetchBonuses();

    if (error && error.response.status === 400) {
      // // console.log(result);
      // return error.response.data;
    }

    if (result) {
      dispatch(addBonuses(result));
      // await dispatch(fetchBonusesImages({ mobile }));
    }

    return null;
  }
);
export const fetchMyActivatedBonuses = createAsyncThunk<
  null,
  {},
  { state: IStore }
>(BonusesAction.FETCH_MY_ACTIVATED_BONUSES, async (_, { dispatch }) => {
  const bonusesService = new BonusesService();

  const { result, error } = await bonusesService.fetchActiveBonuses();

  if (error && error.response.status === 400) {
    // console.log(result);
    // return error.response.data;
  }

  if (result) {
    // console.log(result);
    dispatch(addMyActivatedBonuses(result));
  }

  return null;
});
export const fetchAvailableBonuses = createAsyncThunk<
  null,
  {},
  { state: IStore }
>(BonusesAction.FETCH_AVAILABLE_BONUSES, async (_, { dispatch }) => {
  const bonusesService = new BonusesService();

  const { result, error } = await bonusesService.fetchAvailableBonuses();

  if (error && error.response.status === 400) {
    // console.log(result);
    // return error.response.data;
  }

  if (result) {
    // console.log(result);
    dispatch(addAvailableBonuses(result));
  }

  return null;
});

export const activateGlobalBonus = createAsyncThunk<
  { message: string },
  { promocode: string },
  { state: IStore }
>(BonusesAction.ACTIVATE_GLOBAL_BONUS, async (_, { getState, dispatch }) => {
  const { promocode } = _;

  const bonusesService = new BonusesService();

  const { result, error } = await bonusesService.activateBonus(promocode);

  if (error && error.response.status === 400) {
    return error.response.data.error;
  }

  if (result) {
    // console.log(result.message);
  }

  return result.message;
});

// export const fetchBonusesImages = createAsyncThunk<
//   void,
//   { mobile: boolean },
//   { state: IStore }
// >(BonusesAction.FETCH_BONUSES_IMAGES, async (_, { getState, dispatch }) => {
//   const { mobile } = _;
//   const bonusesService = new BonusesService();
//   const bonusesCopy = getState().bonuses.bonuses;
//   bonusesCopy.forEach(async (bonus) => {
//     for (const key in mobile ? bonus.images.mobile : bonus.images.desktop) {
//       switch (key) {
//         case "bg_image":
//           if (
//             mobile
//               ? bonus.images.mobile.bg_image.name
//               : bonus.images.desktop.bg_image.name
//           ) {
//             const file = await bonusesService.fetchBonusImage(
//               mobile
//                 ? bonus.images.mobile.bg_image.name
//                 : bonus.images.desktop.bg_image.name
//             );
//             const reader = new FileReader();
//             reader.onload = (e) => {
//               if (e.target && e.target.result) {
//                 dispatch(
//                   addBonusBGImage({
//                     id: bonus.id,
//                     mobile,
//                     src: e.target.result.toString(),
//                   })
//                 );
//               }
//             };
//             reader.readAsDataURL(file);
//           }
//           continue;
//         case "sub_image":
//           const file = await bonusesService.fetchBonusImage(
//             mobile
//               ? bonus.images.mobile.sub_image.name
//               : bonus.images.desktop.sub_image.name
//           );
//           const reader = new FileReader();
//           reader.onload = (e) => {
//             if (e.target && e.target.result) {
//               dispatch(
//                 addBonusSubImage({
//                   id: bonus.id,
//                   mobile,
//                   src: e.target.result.toString(),
//                 })
//               );
//             }
//           };
//           reader.readAsDataURL(file);
//           continue;
//       }
//     }
//   });
// });

// export const fetchMyActivatedBonusesImages = createAsyncThunk<
//   void,
//   { mobile: boolean },
//   { state: IStore }
// >(
//   BonusesAction.FETCH_MY_ACTIVATED_BONUSES_IMAGES,
//   async (_, { getState, dispatch }) => {
//     const { mobile } = _;
//     const bonusesService = new BonusesService();
//     const bonusesCopy = getState().bonuses.activatedBonuses;
//     bonusesCopy.forEach(async (bonus) => {
//       const file = await bonusesService.fetchBonusImage(
//         mobile
//           ? bonus.bonus.images.mobile.sub_image.name
//           : bonus.bonus.images.desktop.sub_image.name
//       );
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         if (e.target && e.target.result) {
//           dispatch(
//             addMyActivatedBonusSubImage({
//               id: bonus.id,
//               mobile,
//               src: e.target.result.toString(),
//             })
//           );
//         }
//       };
//       reader.readAsDataURL(file);
//     });
//   }
// );
// export const fetchAvailableBonusesImages = createAsyncThunk<
//   void,
//   { mobile: boolean },
//   { state: IStore }
// >(BonusesAction.FETCH_BONUSES_IMAGES, async (_, { getState, dispatch }) => {
//   const { mobile } = _;
//   const bonusesService = new BonusesService();
//   const bonusesCopy = getState().bonuses.available;
//   bonusesCopy.forEach(async (bonus) => {
//     for (const key in mobile ? bonus.images.mobile : bonus.images.desktop) {
//       switch (key) {
//         case "bg_image":
//           if (
//             mobile
//               ? bonus.images.mobile.bg_image.name
//               : bonus.images.desktop.bg_image.name
//           ) {
//             const file = await bonusesService.fetchBonusImage(
//               mobile
//                 ? bonus.images.mobile.bg_image.name
//                 : bonus.images.desktop.bg_image.name
//             );
//             const reader = new FileReader();
//             reader.onload = (e) => {
//               if (e.target && e.target.result) {
//                 dispatch(
//                   addAvailableBonusBGImage({
//                     id: bonus.id,
//                     mobile,
//                     src: e.target.result.toString(),
//                   })
//                 );
//               }
//             };
//             reader.readAsDataURL(file);
//           }
//           continue;
//         case "sub_image":
//           const file = await bonusesService.fetchBonusImage(
//             mobile
//               ? bonus.images.mobile.sub_image.name
//               : bonus.images.desktop.sub_image.name
//           );
//           const reader = new FileReader();
//           reader.onload = (e) => {
//             if (e.target && e.target.result) {
//               dispatch(
//                 addAvailableBonusSubImage({
//                   id: bonus.id,
//                   mobile,
//                   src: e.target.result.toString(),
//                 })
//               );
//             }
//           };
//           reader.readAsDataURL(file);
//           continue;
//       }
//     }
//   });
// });

export const fetchBonusById = createAsyncThunk<
  any,
  { bonusId: number },
  { state: IStore }
>(BonusesAction.FETCH_BONUS_BY_ID, async (_, { rejectWithValue }) => {
  const { bonusId } = _;
  const bonusesService = new BonusesService();
  const { result, error } = await bonusesService.fetchBonusByID(bonusId);
  if (error && error.response.status === 400) {
    return error.response.data;
  }

  if (error) {
    console.log(error);
    return rejectWithValue(error);
  }

  if (result) {
    return result.bonus;
  }
});
